$dark: #2d3436;
$ligth : #ecf0f1;
$red: #ff7675;
$blue: #0984e3;
$green: #009578;


.overlay {
    position: fixed;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 998;
}

.modal {
    position: fixed;
    top: 30%;
    display: flex;
    flex-direction: column;
    min-width: 30%;
    background-color: $ligth;
    border-radius: 5px;
    z-index: 999;

    .modalHeader {
        display: flex;
        justify-content: flex-end;
        padding: .5rem;

        .close {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            width: 1rem;
            height: 1rem;
            background-color: $red;
            font-size: .8rem;
            cursor: pointer;

            svg {
                display: none;
            }

            &:hover {
                transform: scale(1.1);

                svg {
                    display: unset;
                }
            }

        }
    }

    .modalBody {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 1rem;
        gap: 1rem;

        .categories {
            user-select: none;

            width: 50%;



            .select {
                position: relative;

                .category {
                    border-radius: 5px 5px 0 0;
                }

                span {
                    display: block;
                    width: 100%;
                    padding: .5rem;
                    background-color: darken($ligth, 5%);
                    cursor: pointer;
                    letter-spacing: 1px;
                    font-weight: 100;
                }




                .options {
                    top: 2.1rem;
                    position: absolute;
                    flex-direction: column;
                    width: 100%;


                    span {
                        font-size: .9rem;

                        &:hover {
                            background-color: darken($ligth, 10%);
                        }

                        &:nth-child(1) {
                            border-top: solid 1px darken($ligth, 10%);
                        }

                        &:nth-last-child(1) {
                            border-radius: 0 0 5px 5px;
                        }
                    }
                }
            }
        }


        .dropZone {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 500px;
            height: 300px;
            font-weight: 400;
            font-size: 1.2rem;
            cursor: pointer;
            border: 2px dashed $green;
            border-radius: 10px;
            color: $dark;
            user-select: none;

            input[type="file"] {
                display: none;
            }
        }

        .dropZoneOver {
            border-style: solid;
        }

        .links {
            width: 500px;
            height: 300px;
            background-color: $ligth;
            border: dashed 2px $green;
            padding: 1rem;
            border-radius: 10px;
            font-size: .8rem;
            line-height: 1.2rem;
            resize: none;

            &::placeholder {
                position: absolute;
                top: 47%;
                left: 0;
                text-align: center;
                width: 100%;
                font-family: 'Jura', sans-serif;
                font-weight: 400;
                font-size: 1.2rem;
                color: $dark;

            }
        }
    }

    .modalFooter {
        padding: .5rem;

        display: flex;
        justify-content: center;
        align-items: center;
        gap: 2rem;

        button {
            padding: .5rem 1rem;
            border-radius: 5px;
            color: $ligth;
            letter-spacing: 1px;
        }

        .bgBlue {
            background-color: $blue;
        }

        .bgRed {
            background-color: $red;
        }
    }

}

.error {
    animation: wizz .5s linear;
    border: solid 1px $red !important;
}

@keyframes wizz {

    0%,
    100% {
        transform: translateX(0);
    }

    20%,
    60% {
        transform: translateX(-5%);
    }

    40%,
    80% {
        transform: translateX(5%);
    }
}