$bk : #ecf0f1;
$textColor: #2d3436;
$textColorLigth: #ecf0f1;
$headNav: #ecf0f1;
$badge: #a29bfe;
$badgeIcon: #ecf0f1;
$plus: #3498db;
$ongletHover: #a29bfe;
$red: #ff7675;
$blue: #74b9ff;
$green: #5cd2c6;
$orange: #ff9f43;
$purple: #a29bfe;
$films: #ff7675;
$animes: #55efc4;
$mangas: #a29bfe;
$series: #0984e3;



main {
    display: flex;
    justify-content: center;
}

.container {
    position: relative;
    display: flex;
    flex-direction: column;

    // margin-top: 1rem;
    width: 100%;
    max-width: 800px;
    // max-height: 90vh;
    overflow-y: auto;

    .onglets {
        position: sticky;

        top: 0;
        padding: 1rem;
        display: flex;
        justify-content: space-between;
        gap: 1rem;
        font-size: 1.1rem;
        font-weight: 700;
        background-color: $bk;
        z-index: 1;

        .onglet {
            display: flex;
            align-items: center;
            // justify-content: center;
            gap: .5rem;
            padding: .5rem;
            cursor: pointer;

            svg {
                width: 2rem;
                // height: 50px;
                color: lighten($textColor, 15%);
            }

            svg:hover {
                width: 2rem;
                color: $ongletHover;
            }

            &:active {
                transform: scale(0.95);
            }
        }

        .active svg {
            color: #00b894;
        }

    }

    .items {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding: 1rem;
        user-select: none;

        .nothing {
            display: flex;
            justify-content: center;
            padding: 1rem 0;
            // position: absolute;
            // top: 50%;
            // left: 50%;
            // transform: translate(-50%);
            // white-space: nowrap;
        }

        .itemWaiting {
            position: relative;
            display: flex;
            align-items: center;
            gap: 1rem;
            padding: 0 1rem;

            .waiting {
                color: $purple;
                animation: spinner 2s linear infinite;
            }

            .queued {
                color: $green;
            }
        }

        .completeItem,
        .errorItem {
            display: flex;
            overflow: hidden;
        }

        .errorItem .error {
            padding: .2rem;
            display: block;
            text-align: center;
            color: $red;
        }

        .name {
            font-size: 1.1rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-weight: 600;

        }

        .item {
            position: relative;
            padding: 1rem;
            border-radius: 5px;


            .infos {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;



                .badges {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: flex-start;
                    gap: 1rem;
                    padding: 0.5em 0;

                    .badge {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        gap: .5rem;
                        width: 100px;
                        background-color: $badge;
                        font-size: 0.9rem;
                        font-weight: bold;
                        padding: 0.4rem;
                        border-radius: 5px;



                        span {
                            white-space: nowrap;
                        }

                        svg {
                            font-size: 1rem;
                            color: lighten($textColor, 5%);
                        }

                        .complete {
                            background-color: rgba(85, 239, 196, 0.7);
                            border: solid 1px $bk;
                            color: $textColor;
                        }

                    }

                    @media screen and (max-width: 500px) {
                        justify-content: space-between;

                        .badge {
                            width: 80px;
                            font-size: 0.7rem;

                            svg {
                                font-size: .8rem;
                            }
                        }
                    }
                }
            }
        }
    }
}

li {
    min-height: 50px;
}


/* ContextMenu */
.controls {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 0;
    display: none;
    justify-content: space-around;
    align-items: center;
    gap: 2rem;
    font-size: 2rem;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 5px;
    animation: controls .5s ease-in-out forwards;

    .play {
        cursor: pointer;
        color: $blue;
    }

    .pause {
        cursor: pointer;
        color: $orange;
    }

    .trash {
        cursor: pointer;
        color: $red;
    }
}

/* Menu Goo */

.goo {
    &__wrapper {
        filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1'><defs><filter id='goo'><feGaussianBlur in='SourceGraphic' stdDeviation='10' result='blur'/><feColorMatrix in='blur' mode='matrix' values='1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7' result='goo'/><feBlend in='SourceGraphic' in2='goo'/></filter></defs></svg>#goo");
        width: 50px;

    }
}

.goo__list {
    list-style: none;
    padding: 0;
    margin: 0;
    position: absolute;
}

.goo__btn {
    position: absolute;
    top: 0;
    left: 0;
    background-color: $blue;
    border: none;
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $textColorLigth;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    // font-size: 1rem;
    transition: 0.6s top, 0.4s background, 0.4s color;
    cursor: pointer;

    &:hover {
        background-color: $purple;
    }

    &:hover,
    &:focus,
    &:active {
        outline: none;
    }

    &__1.goo__active {
        top: 55px;
    }

    &__2.goo__active {
        top: 110px;
    }

}


.goo__toggler {
    position: absolute;
    background-color: $blue;
    border: none;
    color: $textColorLigth;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    z-index: 10;
    cursor: pointer;
    font-size: 28px;
    transition: 0.6s all ease-in-out;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;

    &.goo__active {
        transform: rotate(315deg);
    }

    &:focus,
    &:active {
        outline: none;
    }
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);

    }

    100% {
        transform: rotate(-360deg);
    }

}

@keyframes controls {
    0% {
        width: 0;
        left: 50%;
    }

    100% {
        width: 100%;
        left: 0;
    }

}

.footer {
    // position: fixed;
    // bottom: 0;
    width: 100%;
    background-color: $textColor;
    padding: .5rem;

    .onglets {
        display: flex;
        justify-content: space-around;



        .onglet {
            display: flex;
            justify-content: space-around;
            align-items: center;

            span {
                display: none;
            }

            svg {
                font-size: 1.5rem;
                color: $bk;
            }

        }

        .active svg {
            color: $green;
        }

        .goo__btn {
            font-size: 1.2rem;

            &__1.goo__active {
                top: -55px;
            }

            &__2.goo__active {
                top: -110px;
            }
        }
    }
}