@import url('https://fonts.googleapis.com/css2?family=Jura:wght@300;400;500;600;700&display=swap');

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    list-style-type: none;
    text-decoration: none;
    border: none;
    outline: none;
    -webkit-tap-highlight-color: transparent;
}

body {
    height: 100svh;
    overflow: hidden;
    overflow-x: hidden;
    scrollbar-width: none;
}

#root {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100svh;
    max-height: 100svh;
    background-color: #ecf0f1;
    font-family: 'Jura', sans-serif;
}

::-webkit-scrollbar {
    width: 0px;
    height: 0px;
}

::-webkit-scrollbar-track,
::-webkit-scrollbar-corner {
    background: transparent;
}

::-webkit-scrollbar-thumb {
    background-color: rgba(52, 73, 94, 0.2);
    border: 1px solid rgba(52, 73, 94, 1);
    border-radius: 10px;
}