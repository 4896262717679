$bk: #ecf0f1;
$blue: #74b9ff;
$green: #b2bec3;

form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1.5rem;

    .connection {
        align-self: flex-start;
        background-color: $blue;
        padding: .5rem 1rem;
        border-radius: 5px;
        font-family: Jura, sans-serif;
        font-weight: 600;
        letter-spacing: .5px;
        cursor: pointer;

        &:disabled {
            background-color: $green;
        }
    }
}

.waveGroup {
    position: relative;


    .input {

        font-size: 16px;
        padding: 10px 10px 10px 5px;
        display: block;
        width: 100%;
        border: none;
        border-bottom: 1px solid #515151;
        background: transparent;

        &:focus {
            outline: none;
        }

        &:-webkit-autofill~label .labelChar,
        &:focus~label .labelChar,
        &:valid~label .labelChar {
            transform: translateY(-20px);
            font-size: 14px;
            color: #5264AE;
        }

        &:focus~.bar:before,
        &:focus~.bar:after {
            width: 50%;
        }

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
            -webkit-box-shadow: 0 0 0 100px $bk inset !important;
            // -webkit-text-fill-color: initial;
        }

        &:-webkit-autofill {
            // -webkit-text-fill-color: $bk;
            animation-duration: 50000s;
            animation-name: onautofillstart;
        }

        &:not(:-webkit-autofill) {
            animation-duration: 50000s;
            animation-name: onautofillcancel;
        }
    }


    .label {
        color: #999;
        font-size: 18px;
        font-weight: normal;
        position: absolute;
        pointer-events: none;
        left: 5px;
        top: 10px;
        display: flex;


        .labelChar {
            min-width: .3rem;
            transition: 0.2s ease all;
            transition-delay: calc(var(--index) * .05s);
        }
    }


    .bar {
        position: relative;
        display: block;
        width: 100%;

        &::before,
        &::after {
            content: '';
            height: 2px;
            width: 0;
            bottom: 1px;
            position: absolute;
            background: #5264AE;
            transition: 0.2s ease all;
            -moz-transition: 0.2s ease all;
            -webkit-transition: 0.2s ease all;
        }

        &::before {
            left: 50%;
        }

        &::after {
            right: 50%;
        }
    }



}



@keyframes onautofillstart {
    from {}
}

@keyframes onautofillcancel {
    from {}
}