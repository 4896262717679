$bk: #ecf0f1;
$dark: #2d3436;
$headNav: #ecf0f1;
$red: #ff7675;



header {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    background-color: $dark;
    color: $headNav;
    padding: 0 1rem;
    height: 80px;

    svg {
        font-size: 1.5rem;
    }

    img {
        height: 60px;
    }

    nav ul {
        display: flex;
        gap: 1rem;
        text-transform: uppercase;
        font-weight: 700;
    }

    .logout:hover {
        cursor: pointer;
        color: $red;
    }
}

main {
    flex: 1;
    overflow: hidden;
    background-color: $bk;
}

// footer {
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     background-color: $dark;
//     color: $headNav;
//     height: 35px;
//     padding: 0 1rem;

//     svg {
//         font-size: 1.5rem;
//     }

//     small {
//         // color: #636e72;
//         color: lighten($dark, 40%);
//         font-weight: 700;
//     }

// }