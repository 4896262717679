$gooey__loader__size: 120px;
$gooey__loader__dot-size: 40px;

.gooey__loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    filter: url(#goo);

    width: $gooey__loader__size;
    height: $gooey__loader__size;

    &__dot {
        position: absolute;
        top: 50%;
        left: 50%;

        width: $gooey__loader__dot-size;
        height: $gooey__loader__dot-size;
        transform: translate(-50%, #{($gooey__loader__size / -2) + ($gooey__loader__dot-size / -2)});
        transform-origin: 50% #{($gooey__loader__size / 2) + ($gooey__loader__dot-size / 2)};
        border-radius: 50%;

        animation: rotate 2s infinite linear;

        &:nth-child(2n + 1) {
            animation: rotate2 2s infinite linear;
        }

        &:nth-child(1) {
            animation-duration: 1s;
            background: #03a9f4;
        }

        &:nth-child(2) {
            animation-duration: 1.2s;
            background: #5af158;
        }

        &:nth-child(3) {
            animation-duration: 1.4s;
            background: #ffc107;
        }

        &:nth-child(4) {
            animation-duration: 1.6s;
            background: #ff5722;
        }
    }
}

@keyframes rotate {
    0% {
        transform: translate(-50%, #{($gooey__loader__size / -2) + ($gooey__loader__dot-size / -2)}) rotate(0deg) scale(0.8, 0.8)
    }

    50% {
        transform: translate(-50%, #{($gooey__loader__size / -2) + ($gooey__loader__dot-size / -2)}) rotate(180deg) scale(0.7, 0.7)
    }

    100% {
        transform: translate(-50%, #{($gooey__loader__size / -2) + ($gooey__loader__dot-size / -2)}) rotate(360deg) scale(0.8, 0.8)
    }
}

@keyframes rotate2 {
    0% {
        transform: translate(-50%, #{($gooey__loader__size / -2) + ($gooey__loader__dot-size / -2)}) rotate(0deg) scale(0.7, 0.7)
    }

    50% {
        transform: translate(-50%, #{($gooey__loader__size / -2) + ($gooey__loader__dot-size / -2)}) rotate(180deg) scale(0.8, 0.8)
    }

    100% {
        transform: translate(-50%, #{($gooey__loader__size / -2) + ($gooey__loader__dot-size / -2)}) rotate(360deg) scale(0.7, 0.7)
    }
}